import { useState } from "react";

import { motion, useScroll, useTransform } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller } from "swiper";
import SwiperNavButtons from "./SwiperNavButtons";

import "swiper/css/bundle";

const Process = () => {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const pagination = {
    type: "fraction",
    el: ".process-swiper-pagination",
  };

  const { scrollYProgress } = useScroll({
    offset: [0.28, 0.37],
  });
  const moveImageY = useTransform(scrollYProgress, [0, 1], [-200, 60]);

  return (
    <div className="process" id="process">
      <section className="process-slider">
        <div className="container">
          <div className="process-header">
            <p className="process-title">OUR PROCESS</p>
            <div className="process-subtitle-container">
              <div className="process-subtitle-dash"></div>
              <p>THE STEPS WE TAKE TO PERFECT YOUR FLEXBOX</p>
            </div>
          </div>
          <div className="slider-container">
            <div className="process-swiper-pagination"></div>
            <Swiper
              className="process-swiper"
              modules={[Navigation, Pagination, Controller]}
              spaceBetween={60}
              slidesPerView={1}
              speed={1000}
              navigation
              pagination={pagination}
              controller={{ control: controlledSwiper }}
            >
              <SwiperSlide>
                <div className="process-swiper-slide">
                  <div className="process-slide-img-mobile mobile-slide-img-one"></div>
                  <h3>SPECS & DESIGN</h3>
                  <div className="process-swiper-slipe-p">
                    <p>
                      Flexbox recognizes that every customer has unique
                      preferences. That's why they offer a wide range of
                      customization options, allowing you to personalize your
                      Flexbox to suit your style and needs.
                    </p>
                    <p>
                      Whether it's selecting the perfect appliances, finish, or
                      additional features, Flexbox is committed to creating a
                      one-of-a-kind outdoor kitchen container that reflects your
                      individual taste and elevates your outdoor space.
                    </p>
                    <p>
                      We work to realise the perfect specifications that will
                      enhance your outdoor kitchen experience.
                    </p>
                  </div>
                  <a href="#" className="process-cta-link">
                    <div className="process-swiper-cta">
                      <div className="process-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="process-swiper-slide">
                  <div className="process-slide-img-mobile mobile-slide-img-two"></div>
                  <h3>METAL WORK</h3>
                  <div className="process-swiper-slipe-p">
                    <p>
                      Each Flexbox is meticulously handcrafted by skilled
                      artisans who take pride in their work. With years of
                      expertise, they ensure that every component is flawlessly
                      integrated, creating a product that is not only visually
                      stunning but also built to stand the test of time.
                    </p>
                    <p>
                      We spare no expense when it comes to materials. The
                      high-grade stainless steel ensures structural integrity
                      and a top-quality finish that resists weathering and
                      maintains elegance.
                    </p>
                    <p>
                      Every detail is carefully selected to ensure long-lasting
                      beauty and durability.
                    </p>
                  </div>
                  <a href="#" className="process-cta-link">
                    <div className="process-swiper-cta">
                      <div className="process-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="process-swiper-slide">
                  <div className="process-slide-img-mobile mobile-slide-img-three"></div>
                  <h3>FITTINGS & ELECTRICS</h3>
                  <div className="process-swiper-slipe-p">
                    <p>
                      Our electricians seemlesly incorporate the latest tech and
                      conveniently placed wall sockets for all your charging
                      needs.
                    </p>
                    <p>
                      We provide high quality televisions and audio components
                      with hidden wiring to maintain the clean look and feel of
                      your Flexbox.
                    </p>
                  </div>
                  <a href="#" className="process-cta-link">
                    <div className="process-swiper-cta">
                      <div className="process-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="process-swiper-slide">
                  <div className="process-slide-img-mobile mobile-slide-img-four"></div>
                  <h3>FEATURES & TESTING</h3>
                  <div className="process-swiper-slipe-p">
                    <p>
                      Flexbox adheres to strict quality control measures
                      throughout the manufacturing process.
                    </p>
                    <p>
                      Each unit undergoes meticulous inspection and testing to
                      ensure that it meets the highest standards of excellence.
                    </p>
                    <p>
                      This commitment to quality guarantees that your Flexbox
                      will exceed your expectations and provide you with years
                      of reliable performance.
                    </p>
                  </div>
                  <a href="#" className="process-cta-link">
                    <div className="process-swiper-cta">
                      <div className="process-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperNavButtons />
            </Swiper>
            <motion.div
              className="process-swiper-images"
              style={{ bottom: moveImageY }}
            >
              <div className="slider-container-images">
                <Swiper
                  modules={[Controller]}
                  spaceBetween={0}
                  slidesPerView={1}
                  onSwiper={setControlledSwiper}
                >
                  <SwiperSlide className="process-slide-img"></SwiperSlide>
                  <SwiperSlide className="process-slide-img"></SwiperSlide>
                  <SwiperSlide className="process-slide-img"></SwiperSlide>
                  <SwiperSlide className="process-slide-img"></SwiperSlide>
                </Swiper>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Process;
