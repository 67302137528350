const ExtraSplahBlurb = ({ extraInfo }) => {
  return (
    <div className="splash-blurb">
      <div className="container">
        <div className="splash-text">
          <h4>{extraInfo.splashBlurb}</h4>
        </div>
      </div>
      <div className="splash-blurb-img">
        <img src={extraInfo.splashImg} alt="an old tv" />
      </div>
    </div>
  );
};

export default ExtraSplahBlurb;
