import { useRef } from "react";

import { useSwiper } from "swiper/react";

import ArrowL from "../multimedia/vectors/arrowL.svg";
import ArrowR from "../multimedia/vectors/arrowR.svg";

const SwiperNavButtons = () => {
  const swiper = useSwiper();
  const swiperPage = useRef(1);

  return (
    <div className="swiper-nav-btns">
      <div
        className="slidePrev-btn"
        onClick={() => {
          swiper.slidePrev();
          swiperPage.current = swiperPage.current - 1;
          console.log("swiper-page", swiperPage);
        }}
      >
        <img src={ArrowL} alt="arrow left" />
      </div>
      <div
        className="slideNext-btn"
        onClick={() => {
          swiper.slideNext();
          swiperPage.current = swiperPage.current + 1;
          console.log("swiper-page", swiperPage.current);
        }}
      >
        <img src={ArrowR} alt="arrow right" />
      </div>
    </div>
  );
};

export default SwiperNavButtons;
