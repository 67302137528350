import BBQ from "../multimedia/video/FBpromo.mp4";
import BBQ2 from "../multimedia/video/FBpromo2.mp4";

const Hero = () => {
  return (
    <div className="hero" id="hero">
      <section className="hero-banner">
        <div className="hero-banner-vid">
          <video
            className="hero-banner-vid-iframe"
            src={BBQ}
            autoPlay
            loop
            muted
            playsInline
            title="grill food placement"
          ></video>
          <video
            className="hero-banner-vid-iframe-mob"
            src={BBQ2}
            autoPlay
            loop
            muted
            playsInline
            title="grill food placement"
          ></video>
        </div>
        <div className="hero-banner-overlay"></div>
        <div className="container hero-banner-text-container">
          <div className="hero-banner-text">
            <h2>REDEFINING LUXURY</h2>
            <h3>IN OUTDOOR KITCHENS</h3>
            <div className="hero-subtitle-container">
              <p>
                Flexbox sets a new standard for exceptional quality in the realm
                of outdoor kitchens.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
