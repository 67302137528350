import { useState } from "react";

import { motion, useScroll, useSpring, useTransform } from "framer-motion";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Controller } from "swiper";

import SwiperNavButtons from "./SwiperNavButtons";

import "swiper/css/bundle";

const Security = () => {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const pagination = {
    type: "fraction",
    el: ".security-swiper-pagination",
  };

  const { scrollYProgress } = useScroll({
    offset: [0.75, 0.85],
  });

  const dampenY = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const moveImageY = useTransform(dampenY, [0, 1], [-200, 60]);

  return (
    <div id="security">
      <section className="security-slider">
        <div className="container">
          <div className="security-header">
            <p className="security-title">OUR SECURITY</p>
            <div className="security-subtitle-container">
              <div className="security-subtitle-dash"></div>
              <p>THE STEPS WE TAKE TO PROTECT YOUR FLEXBOX</p>
            </div>
          </div>
          <div className="slider-container">
            <div className="security-swiper-pagination "></div>
            <Swiper
              className="security-swiper"
              modules={[Navigation, Pagination, Controller]}
              spaceBetween={60}
              slidesPerView={1}
              speed={1000}
              navigation
              pagination={pagination}
              controller={{ control: controlledSwiper }}
            >
              <SwiperSlide>
                <div className="security-swiper-slide">
                  <div className="security-slide-img-mobile sec-mobile-slide-img-one"></div>
                  <h3>SECURE LOCKING</h3>
                  <div className="security-swiper-slipe-p">
                    <p>
                      We understand the importance of safeguarding your valuable
                      outdoor kitchen equipment.
                    </p>
                    <p>
                      Our product features an advanced secure locking system,
                      engineered to keep intruders at bay. Rest easy knowing
                      that your appliances, utensils, and ingredients are
                      protected by cutting-edge technology, ensuring that only
                      authorized individuals have access.
                    </p>
                    <p>
                      Embrace the luxury of knowing your outdoor kitchen is
                      shielded by the most robust locking mechanism available.
                    </p>
                  </div>
                  <a href="#" className="security-cta-link">
                    <div className="security-swiper-cta">
                      <div className="security-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="security-swiper-slide">
                  <div className="security-slide-img-mobile sec-mobile-slide-img-two"></div>
                  <h3>REMOTE ACCESS</h3>
                  <div className="security-swiper-slipe-p">
                    <p>
                      Experience a new level of convenience and control with our
                      remote control access feature.
                    </p>
                    <p>
                      Imagine being able to access and manage your outdoor
                      kitchen's security with the press of a button.
                    </p>
                    <p>
                      Our included remote control allows you to conveniently
                      lock or unlock your kitchen from a distance, giving you
                      complete control over its accessibility. Whether you're
                      inside your home or lounging by the pool, you can
                      effortlessly secure your outdoor kitchen and enjoy peace
                      of mind.
                    </p>
                  </div>
                  <a href="#" className="security-cta-link">
                    <div className="security-swiper-cta">
                      <div className="security-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="security-swiper-slide">
                  <div className="security-slide-img-mobile sec-mobile-slide-img-three"></div>
                  <h3>STRONG CASING</h3>
                  <div className="security-swiper-slipe-p">
                    <p>
                      Built to withstand the elements and deter any potential
                      threats, our luxury outdoor kitchen boasts an impenetrable
                      strong casing.
                    </p>
                    <p>
                      Crafted from the finest materials and reinforced with
                      state-of-the-art construction techniques, this durable
                      enclosure offers unparalleled resilience against
                      vandalism, theft, and harsh weather conditions.
                    </p>
                    <p>
                      From high-quality stainless steel to impact-resistant
                      coatings, our strong casing ensures your outdoor kitchen
                      remains an impenetrable fortress, guarding your investment
                      year after year.
                    </p>
                  </div>
                  <a href="#" className="security-cta-link">
                    <div className="security-swiper-cta">
                      <div className="security-swiper-cta-dash"></div>
                      <p>ENQUIRE NOW</p>
                    </div>
                  </a>
                </div>
              </SwiperSlide>
              <SwiperNavButtons />
            </Swiper>
          </div>
        </div>
        <motion.div
          className="security-swiper-images"
          style={{ bottom: moveImageY }}
        >
          <div className="slider-container-images">
            <Swiper
              modules={[Controller]}
              spaceBetween={0}
              slidesPerView={1}
              onSwiper={setControlledSwiper}
            >
              <SwiperSlide className="security-slide-img"></SwiperSlide>
              <SwiperSlide className="security-slide-img"></SwiperSlide>
              <SwiperSlide className="security-slide-img"></SwiperSlide>
            </Swiper>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default Security;
