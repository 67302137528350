import React from "react";

const BigBlurb = () => {
  return (
    <div className="big-blurb">
      <section className="big-blurb-container">
        <div className="container">
          <div className="big-blurb-text-container">
            <div className="big-blurb-text-box">
              <h3>
                Crafted with utmost precision and attention to detail, each
                Flexbox is made to order, ensuring a unique and tailored
                experience for every customer.
              </h3>
            </div>
          </div>
        </div>
      </section>
      <section className="big-blurb-cta-container">
        <div className="container">
          <p className="big-blurb-cta-call">
            <font>INTERESTED?</font>
          </p>
          <a href="#">
            <h3 className="big-blurb-cta">
              <font>FIND OUT MORE</font>
              <div className="find-out-line"></div>
            </h3>
          </a>
        </div>
      </section>
    </div>
  );
};

export default BigBlurb;
