import Layout from "./components/Layout.jsx";
import Home from "./components/Home.jsx";
import ExtraInfo from "./components/ExtraInfo";

import { Routes, Route } from "react-router-dom";

import { extraInfo } from "./data/extra-info-data.js";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route
            path="/television"
            element={<ExtraInfo extraInfo={extraInfo.television} />}
          />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
