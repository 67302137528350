import { Link } from "react-router-dom";

const Features = () => {
  return (
    <div className="features" id="features">
      <section className="features-container">
        <div className="container">
          <p className="features-cta-call">
            <font>BESPOKE CUSTOMISATION</font>
          </p>
          <div className="features-info-container">
            <p className="features-info-box">
              <font>
                Experience Flexbox, where luxury and convenience meet your
                outdoor dreams. Create unforgettable memories and embrace the
                freedom of a customized outdoor kitchen container that combines
                style, technology, and exceptional craftsmanship.
              </font>
            </p>
          </div>
          <div className="features-cards-container">
            <div className="features-card-box">
              <a className="features-card" href="#">
                <div className="features-card-img-container">
                  <div className="features-card-img img-one"></div>
                  <div className="features-card-img-hover img-one-hover"></div>
                </div>
                <div className="features-card-title">
                  <h4>GRILL LIKE A PRO</h4>
                </div>
              </a>
            </div>
            <div className="features-card-box">
              <a className="features-card" href="#">
                <div className="features-card-img-container">
                  <div className="features-card-img img-two"></div>
                  <div className="features-card-img-hover img-two-hover"></div>
                </div>
                <div className="features-card-title">
                  <h4>KEEP IT FRESH</h4>
                </div>
              </a>
            </div>
            <div className="features-card-box">
              <Link className="features-card" to="/television">
                <div className="features-card-img-container">
                  <div className="features-card-img img-three"></div>
                  <div className="features-card-img-hover img-three-hover"></div>
                </div>
                <div className="features-card-title">
                  <h4>PREMIUM TECH</h4>
                </div>
              </Link>
            </div>
          </div>
          <div className="features-cta-container">
            <a href="#" className="features-cta-link">
              <div className="features-cta">
                <div className="features-cta-dash"></div>
                <p>BUILD YOUR OWN FLEXBOX</p>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
