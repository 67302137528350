const ExtraBlurb1 = ({ extraInfo }) => {
  return (
    <div className="big-blurb">
      <section className="big-blurb-container">
        <div className="container">
          <div className="big-blurb-text-container">
            <div className="big-blurb-text-box">
              <h3>{extraInfo.blurb1}</h3>
              <p className="big-blurb-subtext">{extraInfo.subBlurb1}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExtraBlurb1;
