import FBlogo from "../multimedia/vectors/FBwhite.svg";

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="upper-footer">
          <div className="footer-nav-links-container">
            <div className="footer-nav-links">
              <div className="footer-nav-links-top">
                <ul className="footer-nav-links-col">
                  <li className="footer-nav-link">
                    <a href="#features">
                      <h4>FEATURES</h4>
                      <div className="footer-link-line"></div>
                    </a>
                  </li>
                  <li className="footer-nav-link">
                    <a href="#process">
                      <h4>OUR PROCESS</h4>
                      <div className="footer-link-line"></div>
                    </a>
                  </li>{" "}
                  <li className="footer-nav-link">
                    <a href="#quality">
                      <h4>BUILD QUALITY</h4>
                      <div className="footer-link-line"></div>
                    </a>
                  </li>{" "}
                  <li className="footer-nav-link">
                    <a href="#security">
                      <h4>SECURITY</h4>
                      <div className="footer-link-line"></div>
                    </a>
                  </li>
                </ul>
                <ul className="footer-nav-links-col">
                  <li className="footer-nav-link">
                    <a href="#">
                      <h4>BUILD A BOX</h4>
                      <div className="footer-link-line"></div>
                    </a>
                  </li>
                  <li className="footer-nav-link">
                    <a href="#">
                      <h4>SHOWROOM</h4>
                      <div className="footer-link-line"></div>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-logo">
                <a href="#hero">
                  <img src={FBlogo} alt="flexbox logo" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-nav-links-container nav-links-container-right">
            <div className="footer-title">
              <p>CONTACT US</p>
            </div>
            <div className="contact-info">
              <a href="#">
                <div className="email">
                  <h3>sales@theflexbox.co.uk</h3>
                  <div className="contact-line"></div>
                </div>
              </a>
              <a href="#">
                <div className="telephone">
                  <h3>
                    0161 706 0917
                    <div className="contact-line"></div>
                  </h3>
                </div>
              </a>
            </div>
            <div className="footer-blurb-container">
              <div className="footer-blurb">
                <p>
                  Flexbox recognises that every customer has unique preferences.
                  That's why we offer a wide range of customisation options,
                  allowing you to personalise your Flexbox to suit your style
                  and needs. We are committed to creating a one-of-a-kind
                  outdoor kitchen container that reflects your individual taste
                  and elevates your outdoor space.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-footer">
          <div className="footer-copyright">
            <p>©flexbox2023</p>
          </div>
          <ul className="footer-socials">
            <li>facebook</li>
            <li>instagram</li>
            <li>linkedin</li>
            <li>twitter</li>
          </ul>
          <div className="return-to-top">RETURN TO TOP </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
