import React from "react";

const ExtraBlurb2 = ({ extraInfo }) => {
  return (
    <div className="big-blurb2">
      <section className="big-blurb-container">
        <div className="container">
          <div className="big-blurb2-text-container">
            <div className="big-blurb2-text-box">
              <h3>{extraInfo.blurb2Title}</h3>
              <p className="big-blurb2-subtext">{extraInfo.subBlurb1}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExtraBlurb2;
