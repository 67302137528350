import { motion } from "framer-motion";

const Brochure = () => {
  return (
    <motion.div
      className="brochure"
      animate={{ right: 0 }}
      transition={{ ease: "easeOut", duration: 1.5 }}
    >
      <a href="https://flexbox-brochure.s3.eu-west-1.amazonaws.com/Flexbox+Brochure-1.png">
        <button className="brochure-btn">
          <h4>GET BROCHURE</h4>
        </button>
      </a>
    </motion.div>
  );
};

export default Brochure;
