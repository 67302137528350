import ExtraBlurb1 from "./ExtraBlurb1";
import ExtraBlurb2 from "./ExtraBlurb2";
import ExtraHero from "./ExtraHero";
import ExtraNav from "./ExtraNav";
import ExtraProduct from "./ExtraProduct";
import ExtraSplahBlurb from "./ExtraSplahBlurb";

const ExtraInfo = ({ extraInfo }) => {
  console.log(extraInfo);
  return (
    <div className={`extra-info ExtraInfo-${extraInfo.name}`}>
      <ExtraNav />
      <ExtraHero extraInfo={extraInfo} />
      <ExtraBlurb1 extraInfo={extraInfo} />
      <ExtraProduct extraInfo={extraInfo} />
      <ExtraBlurb2 extraInfo={extraInfo} />
      <ExtraSplahBlurb extraInfo={extraInfo} />
    </div>
  );
};

export default ExtraInfo;
