import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css/bundle";

const Partners = () => {
  return (
    <div className="partners">
      <section className="partners-sec">
        <div className="container">
          <div className="partners-title">
            <p>OUR PARTNERS</p>
          </div>
          <div className="partners-swiper-container">
            <Swiper
              className="partners-swiper"
              modules={[Navigation, Autoplay]}
              spaceBetween={0}
              slidesPerView={3}
              speed={1000}
              autoplay={{ reverseDirection: true }}
              pagination
            >
              <SwiperSlide className="partners-logo"></SwiperSlide>
              <SwiperSlide className="partners-logo"></SwiperSlide>
              <SwiperSlide className="partners-logo"></SwiperSlide>
              <SwiperSlide className="partners-logo"></SwiperSlide>
              <SwiperSlide className="partners-logo"></SwiperSlide>
              <SwiperSlide className="partners-logo"></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
