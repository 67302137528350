import { useEffect, useRef } from "react";
import Guarantee from "../multimedia/images/GuaranteeFB.png";

import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useInView,
  animate,
} from "framer-motion";

const Quality = () => {
  const { scrollYProgress } = useScroll({
    offset: [0.35, 0.7],
  });

  const dampenY = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const y = useTransform(scrollYProgress, [0, 1], ["-50%", "50%"]);

  const qualityImageScroll = useTransform(
    dampenY,
    [0.5, 0.75],
    ["-20vh", "5vh"]
  );

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "-50%",
  });

  const count = useMotionValue(0);
  const count2 = useMotionValue(0);
  const count3 = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));
  const rounded2 = useTransform(count2, (latest) => Math.round(latest));
  const rounded3 = useTransform(count3, (latest) => Math.round(latest));

  useEffect(() => {
    if (isInView) {
      const controls = animate(count, 36, {
        duration: 2,
      });
      return controls.stop;
    }
  }, [isInView, count]);

  useEffect(() => {
    if (isInView) {
      const controls2 = animate(count2, 90, {
        duration: 2,
      });
      return controls2.stop;
    }
  }, [isInView, count2]);

  useEffect(() => {
    if (isInView) {
      const controls3 = animate(count3, 24, {
        duration: 2,
      });
      return controls3.stop;
    }
  }, [isInView, count3]);

  return (
    <div className="quality">
      <section className="quality-parallax-img-container">
        <motion.img
          className="quality-parallax-img"
          src={Guarantee}
          alt="kitchen"
          style={{ y }}
        />
        <div className="quality-parallax-img-title container">
          <h3>The FLEXBOX Guarantee</h3>
        </div>
      </section>
      <section className="quality-assurance-sec" id="quality">
        <div className="container">
          <div className="quality-assurance-text-container">
            <div className="quality-assurance-text-pre_title">
              <p>BUILD QUALITY</p>
            </div>
            <div className="quality-assurance-text-title">
              <h3>
                Here at FLEXBOX we prioritise quality build materials and
                processes
              </h3>
            </div>
            <div className="quality-assurance-text">
              <p>
                Flexbox sets a new standard for exceptional quality in the realm
                of outdoor kitchen containers. Crafted with meticulous attention
                to detail and using premium materials, Flexbox ensures a
                luxurious and durable experience that will surpass your
                expectations.
              </p>
              <p>
                We adhere to strict quality control measures throughout the
                manufacturing process. Each unit undergoes meticulous inspection
                and testing to ensure that it meets the highest standards of
                excellence.
              </p>
              <p>
                We are dedicated to providing an exceptional customer
                experience. From the moment you make your order to the delivery
                and installation process, their team is there to assist you
                every step of the way. With prompt and responsive customer
                service, they strive to ensure that you are delighted with your
                Flexbox and enjoy the outdoor culinary haven of your dreams.
              </p>
            </div>
            <a href="#" className="quality-cta-link">
              <div className="quality-assurance-cta">
                <div className="quality-assurance-cta-dash"></div>
                <p>ENQUIRE NOW</p>
              </div>
            </a>
          </div>
        </div>
        <div className="quality-assurance-img">
          <motion.img
            style={{ bottom: qualityImageScroll }}
            src="https://images.pexels.com/photos/4480453/pexels-photo-4480453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="workmans tools"
          />
        </div>
      </section>
      <section className="quality-stats-sec" ref={ref}>
        <div className="container">
          <div className="quality-stats-pre_title">
            <p>BUILD SPECIFICATIONS</p>
          </div>
          <div className="quality-numbers">
            <div className="quality-numbers-item">
              <div className="quality-numbers-item-no">
                <motion.h2>{rounded}</motion.h2>
              </div>
              <div className="quality-numbers-item-text">quality data 1</div>
            </div>
            <div className="quality-numbers-item">
              <div className="quality-numbers-item-no">
                <motion.h2>{rounded2}</motion.h2>
              </div>
              <div className="quality-numbers-item-text">quality data 2</div>
            </div>
            <div className="quality-numbers-item">
              <div className="quality-numbers-item-no">
                <motion.h2>{rounded3}</motion.h2>
              </div>
              <div className="quality-numbers-item-text">quality data 3</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Quality;
