import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";

const ExtraHero = ({ extraInfo }) => {
  const hero = useRef(null);
  const { scrollYProgress } = useScroll({
    offset: ["end center", "center"],
    target: hero,
  });
  const y = useTransform(scrollYProgress, [0, 1], ["-40%", "-50%"]);

  return (
    <div className="hero" id="hero" ref={hero}>
      <section className="hero-banner">
        <div className="hero-banner-vid">
          <motion.img
            className="hero-banner-img"
            src={extraInfo.heroImg}
            style={{ y, x: "-50%" }}
          ></motion.img>
        </div>
        <div className="hero-banner-overlay"></div>
        <div className="container hero-banner-text-container">
          <div className="hero-banner-text">
            <h3>{extraInfo.subtitle}</h3>
            <h2>{extraInfo.title}</h2>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExtraHero;
