import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

const ExtraProduct = ({ extraInfo }) => {
  const productPagination = {
    type: "bullets",
    el: ".product-swiper-pagination",
    clickable: true,
  };

  return (
    <div className="product">
      <section className="product-container">
        <div className="container">
          <div className="product-spacer">
            <div className="product-slider-container">
              <Swiper
                className="product-swiper"
                modules={[Navigation, Pagination]}
                spaceBetween={20}
                slidesPerView={1}
                speed={1000}
                navigation
                pagination={productPagination}
              >
                <SwiperSlide>
                  <div className="product-swiper-slide-container">
                    <img src={extraInfo.sliderImages[0]} alt="tv 1" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-swiper-slide-container">
                    <img src={extraInfo.sliderImages[1]} alt="tv 2" />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="product-swiper-slide-container">
                    <img src={extraInfo.sliderImages[2]} alt="tv 3" />
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="product-slider-bottom">
                <div className="product-swiper-pagination"></div>
              </div>
            </div>
            <div className="product-details-container">
              <h4 className="product-details-top-header">
                {extraInfo.specSubTitle}
              </h4>
              <hr />
              <h2 className="product-details-header">
                {extraInfo.specFeatureTitle}
              </h2>
              <div className="product-details-spec-container">
                <p className="product-details-spec">{extraInfo.spec1}</p>
                <p>{extraInfo.spec1Data}</p>
              </div>
              <div className="product-details-spec-container">
                <p className="product-details-spec">{extraInfo.spec2}</p>
                <p>{extraInfo.spec2Data}</p>
              </div>
              <hr />
              <h3 className="product-details-tech">TECHNICAL DETAILS</h3>
              <div className="product-details-spec-container">
                <p className="product-details-spec">{extraInfo.spec3}</p>
                <p>{extraInfo.spec3Data}</p>
              </div>
              <div className="product-details-spec-container">
                <p className="product-details-spec">{extraInfo.spec4}</p>
                <p>{extraInfo.spec4Data}</p>
              </div>
              <div className="product-details-spec-container">
                <p className="product-details-spec">{extraInfo.spec5}</p>
                <p>{extraInfo.spec5Data}</p>
              </div>
              <hr />
              <div className="product-details-spec-container">
                <p className="product-details-spec">Price: </p>
                <h3>{extraInfo.price}</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ExtraProduct;
