import { useState } from "react";
import { Link } from "react-router-dom";

import { motion, useScroll, useTransform } from "framer-motion";

import FBlogo from "../multimedia/vectors/FBwhite.svg";

const ExtraNav = () => {
  const [burgerOpen, setBurgerOpen] = useState(false);

  const { scrollYProgress } = useScroll({ offset: [0, 0.15] });
  const scaleY = useTransform(scrollYProgress, [0, 0.5], ["0%", "100%"]);

  return (
    <header className="header">
      <motion.div
        className="header-background"
        style={{ height: scaleY, opacity: scaleY }}
      ></motion.div>
      <div className="header-container">
        <div className="header-logo">
          <Link to="/">
            <img src={FBlogo} alt="flexbox logo" />
          </Link>
        </div>
        <nav className="header-nav" id={burgerOpen ? "header-nav-open" : null}>
          <ul className="header-nav-links">
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>TV</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>AUDIO</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>CONNECTIONS</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>GRILL</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>FRIDGE</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>STORAGE</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>REMOTE</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
            <li className="header-nav-link">
              <a
                href="#"
                onClick={() => {
                  setBurgerOpen(!burgerOpen);
                }}
              >
                <p>EXTRAS</p>
                <div className="nav-link-line"></div>
              </a>
            </li>
          </ul>
          <a href="#">
            <div className="header-build">
              <div className="header-build-dash"></div>
              <p>BUILD A BOX</p>
            </div>
          </a>
        </nav>
        <div
          className="header-burger"
          id={burgerOpen ? "burger-open" : "header-burger"}
          onClick={() => {
            setBurgerOpen(!burgerOpen);
          }}
        >
          <motion.div></motion.div>
          <motion.div></motion.div>
          <motion.div></motion.div>
        </div>
      </div>
    </header>
  );
};

export default ExtraNav;
