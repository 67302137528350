export const extraInfo = {
  television: {
    name: "TV",
    heroImg:
      "https://images.philips.com/is/image/philipsconsumer/196953eed99f44f59001afb9011f7e2d?$jpglarge$&wid=1250",
    title: "This is Entertainment",
    subtitle: "TELEVISIONS",
    blurb1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    subBlurb1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices gravida. Nunc lobortis mattis aliquam faucibus purus in. Cursus metus aliquam ",
    sliderImages: [
      "https://johnlewis.scene7.com/is/image/JohnLewis/tv-carousel1-160922",
      "https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6538/6538127cv15d.jpg",
      "https://johnlewis.scene7.com/is/image/JohnLewis/tv-carousel3-070922",
    ],
    specFeatureTitle: "SONY Bravia",
    specSubTitle: "Product Information",
    spec1: "Screen-Size: ",
    spec1Data: "63inches",
    spec2: "Screen-Type: ",
    spec2Data: "OLED",
    spec3: "Picture Quality: ",
    spec3Data: "4k Ultra",
    spec4: "Sound: ",
    spec4Data: "Dolby Atmos",
    spec5: "HDMI ports: ",
    spec5Data: "HDMI 2.0",
    price: "£800",
    blurb2Title: "WHAT ARE YOU WAITING FOR?",
    blurb2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Bibendum ut tristique et egestas quis ipsum suspendisse ultrices gravida.",
    splashBlurb:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ",
    splashImg:
      "https://images.pexels.com/photos/5211580/pexels-photo-5211580.jpeg?cs=srgb&dl=pexels-anna-tarazevich-5211580.jpg&fm=jpg",
  },
};
