import Brochure from "./Brochure";
import Nav from "./Nav";
import Hero from "./Hero";
import BigBlurb from "./BigBlurb";
import Features from "./Features";
import Process from "./Process";
import Quality from "./Quality";
import Security from "./Security";
import Partners from "./Partners";

const Home = () => {
  return (
    <div className="home">
      <Brochure />
      <Nav />
      <Hero />
      <BigBlurb />
      <Features />
      <Process />
      <Quality />
      <Security />
      <Partners />
    </div>
  );
};

export default Home;
